import React from 'react';
import { Field } from 'react-final-form';
import FieldError from '../FieldError';
import RadioButton from '../../../../public/static/icons/radio-button.svg';
import RadioButtonChecked from '../../../../public/static/icons/radio-button-checked.svg';

import styles from './index.module.scss';

type Props = {
  options: RadioButtonGroupOptions[];
  label?: string;
  name: string;
  size?: RadioButtonGroupSize;
  withIcons?: boolean;
  onKeyDown?: (
    e: React.KeyboardEvent,
    item: RadioButtonGroupOptions,
    mutateFormValue?: MutateFormCallback,
  ) => void;
  mutateFormValue?: MutateFormCallback;
};

type MutateFormCallback = (name: string, value: string | number) => void;
type RadioButtonGroupSize = 'normal' | 'large';
type RadioButtonGroupOptions = {
  value?: string | number;
  label?: string;
  className?: string;
};

const RadioButtonGroup = ({
  options,
  label = '',
  name,
  size = 'normal',
  withIcons = false,
  onKeyDown,
  mutateFormValue,
  ...props
}: Props) => {
  const handleKeyDown = (e: React.KeyboardEvent, item: RadioButtonGroupOptions) => {
    if (onKeyDown) {
      onKeyDown(e, item, mutateFormValue);
      return;
    }

    const enter = 13;
    const space = 32;
    if ((e.keyCode === enter || e.keyCode === space) && mutateFormValue && item.value) {
      mutateFormValue(name, item.value);
    }
  };

  const classes = [
    styles['radio-button-group'],
    styles[`radio-button-group--${size}`],
    'field-type-radio-button-group',
    `field-${name.replace('.', '_')}`,
  ];

  return (
    <div className={classes.join(' ')} role="group" aria-labelledby={`radio_button_group_${name}`}>
      {label && (
        <label
          htmlFor={`radio_button_group_${name}`}
          id={`radio_button_group_${name}`}
          className={styles['radio-button-group__label']}
          data-component="radio-button-group__label"
        >
          {label}
        </label>
      )}

      <div className={styles['radio-button-group__buttons']} role="radiogroup" aria-label={name}>
        {options.map((item) => (
          <Field
            name={name}
            key={`${name}_${item.value}`}
            render={({ input }) => (
              <label
                htmlFor={`${name}_${item.value}`}
                tabIndex={0}
                className={`${styles['radio-button-group__button']} ${item.value === input.value ? styles['radio-button-group__button--selected'] : ''} ${item.className ? item.className : ''}`}
                data-component={
                  item.value === input.value
                    ? 'radio-button-group__button--selected'
                    : 'radio-button-group__button'
                }
                aria-labelledby={`${name}_${item.value}`}
                onKeyDown={(e) => handleKeyDown(e, item)}
                aria-checked={item.value === input.value}
                role="radio"
              >
                <Field
                  component="input"
                  aria-label={item.label}
                  type="radio"
                  name={name}
                  value={item.value}
                  id={`${name}_${item.value}`}
                  title={item.value}
                  {...props}
                />
                {withIcons &&
                  (item.value === input.value ? <RadioButtonChecked /> : <RadioButton />)}
                {item.label}
              </label>
            )}
          />
        ))}
      </div>

      <FieldError name={name} />
    </div>
  );
};

export default RadioButtonGroup;
