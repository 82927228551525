import React from 'react';
import styles from './index.module.scss';

const ResultStatsComponent = (firstFilterLabel, countSelectedFiltersForMessage, device) => {
  const ResultStats = (state) => {
    const { searchState } = state;

    const { result } = searchState;
    if (!result) {
      return null;
    }
    const { resultStats } = result;
    if (!resultStats) {
      return null;
    }
    let message = `${resultStats.numberOfResults} ${
      resultStats.numberOfResults > 1 ? 'Resources' : 'Resource'
    }${firstFilterLabel ? ` for ${firstFilterLabel}` : ''}${
      countSelectedFiltersForMessage > 0
        ? ` + ${countSelectedFiltersForMessage} ${
            countSelectedFiltersForMessage === 1 ? 'filter' : 'filters'
          }`
        : ''
    }`;

    if (!firstFilterLabel) {
      if (device === 'mobile') {
        message = 'All resources';
      }
    }

    return (
      <div
        className={`${styles['result-stats']} ${
          device === 'desktop' ? styles['result-stats-desktop'] : styles['result-stats-mobile']
        }`}
      >
        {message}
      </div>
    );
  };

  return ResultStats;
};

export default ResultStatsComponent;
