import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import DonationImpactIcon from '../../../public/static/icons/donation-impact.svg';

import s from './index.module.scss';

const ImpactText = ({ text, image = null, className = '' }) => (
  <div className={`${s['impact-text']} ${className}`} data-container="impact-text">
    <div className={s['impact-text__message']} data-component="impact-text">
      {image && (
        <div
          className={s['impact-text__image']}
          title={image.alt}
          style={{ backgroundImage: `url(${image.url})` }}
        >
          <Head>
            <link rel="preload" href={image.url} as="image" />
          </Head>
        </div>
      )}
      {!image && <DonationImpactIcon className={s['impact-text__icon']} />}
      <div className={s['impact-text__text']}>{text}</div>
    </div>
  </div>
);

ImpactText.propTypes = {
  text: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  className: PropTypes.string,
};

export default ImpactText;
