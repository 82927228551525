import React from 'react';
import PropTypes from 'prop-types';
import DefaultPicture, {
  propTypes as defaultPicturePropTypes,
} from '../../../../01_atoms/DefaultPicture';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';
import LogoWhite from '../../../../../public/static/icons/logos/logo-white.svg';
import Link, { propTypes as linkPropTypes } from '../../../../01_atoms/Link';

import styles from './index.module.scss';

const BBHeroImageLogo = ({ logoLink = null, image, behaviorSettings = null, uuid = null }) => {
  const classes = [
    'bb',
    'bb-hero',
    styles['bb-hero-image-logo'],
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <div id={uuid} className={classes.join(' ')} data-component="bb-hero-image-logo">
      <div className={styles['bb-hero-image-logo__bg']}>
        <div className={styles['bb-hero-image-logo__image']}>
          <DefaultPicture {...image} />
        </div>
        <div className={styles['bb-hero-image-logo__shape']}></div>
      </div>
      {logoLink && (
        <div className={styles['bb-hero-image-logo__content']}>
          <div className={styles['bb-hero-image-logo__site-logo']}>
            <Link {...logoLink}>
              <a tabIndex="0">
                <LogoWhite
                  alt="Concern Worldwide"
                  className={styles['bb-hero-image-logo__logo-image']}
                  aria-label="Concern Worldwide"
                />
              </a>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

BBHeroImageLogo.propTypes = {
  logoLink: PropTypes.shape(linkPropTypes),
  image: PropTypes.shape(defaultPicturePropTypes).isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBHeroImageLogo;
