import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Field, Form, RadiosButton, TextField } from '../../../01_atoms/FormElements';
import { currencySymbolPath, formatAmount, getSymbol } from '../../../../utils/currency';
import Button from '../../../01_atoms/Button';
import HeroImageWithShapedBackground from './HeroImageWithShapedBackground';
import HeroMoneyHandles, { propTypes as moneyHandlePropTypes } from './HeroMoneyHandles';
import { propTypes as imagePropTypes } from '../BBHero';
import { getImpact } from '../../../../utils/donationForm';
import { parseOnlyDigits } from '../../../../utils/formFieldParsers';
import { minValue } from '../../../../utils/formFieldValidators';
import { pushViewItem as gtmPushViewItem } from '../../../../utils/gtm';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const BBHeroWithMoneyHandles = ({
  uuid,
  title,
  image = {},
  currency,
  defaultRecurrence = 'single_donation',
  optionsSingle = [],
  optionsMonthly = [],
  impactsSingleDonation = [],
  impactsMonthlyDonation = [],
  preselectedSingle = 1,
  preselectedMonthly = 1,
  bottomDescription = '',
  singleDonationLabel = 'One-off',
  recurringDonationLabel = 'Give monthly',
  styling = 'normal',
  onSubmit,
  behaviorSettings = null,
  // Data for GA.
  defaultAppealId,
  defaultAppealTitle,
}) => {
  const moneyHandles = {
    single_donation: optionsSingle.map((item, index) => ({
      value: `${index}`,
      label: formatAmount(currency, item.amount),
      ...optionsSingle[index],
    })),
    recurring_donation: optionsMonthly.map((item, index) => ({
      value: `${index}`,
      label: formatAmount(currency, item.amount),
      ...optionsMonthly[index],
    })),
  };

  const initialValues = {
    donation_type: defaultRecurrence,
    // Form values structure always supports both single & monthly donations
    // so it's easier to switch between then and keep the correct state.
    money_handle: {
      single_donation: `${preselectedSingle}`,
      recurring_donation: `${preselectedMonthly}`,
    },
    amount: {
      single_donation: moneyHandles.single_donation[preselectedSingle]
        ? `${moneyHandles.single_donation[preselectedSingle].amount}`
        : '',
      recurring_donation: moneyHandles.recurring_donation[preselectedMonthly]
        ? `${moneyHandles.recurring_donation[preselectedMonthly].amount}`
        : '',
    },
  };

  useEffect(() => {
    if (defaultAppealId) {
      gtmPushViewItem([
        {
          item_id: defaultAppealId,
          item_name: defaultAppealTitle,
          item_category: 'Appeal',
          item_variant: defaultRecurrence,
        },
      ]);
    }
  }, [uuid, defaultAppealId, defaultAppealTitle, defaultRecurrence]);

  // Update selected money handle on custom amount change.
  function onAmountChange(event, input, values, mutators) {
    // Call default onChange handler.
    input.onChange(event);

    const index = moneyHandles[values.donation_type].findIndex(
      (i) => i.amount === parseInt(event.target.value, 10),
    );

    // Store the last valid amount from the money handles to keep impact message for it.
    const currentAmountIndex = moneyHandles[values.donation_type].findIndex(
      (i) => i.amount === parseInt(values.amount[values.donation_type], 10),
    );
    if (currentAmountIndex >= 0) {
      mutators.setValue(
        `money_handle.last_valid.${values.donation_type}`,
        values.amount[values.donation_type],
      );
    }
    if (index === -1) {
      // Reset radio button.
      mutators.setValue(`money_handle.${values.donation_type}`, undefined);
    } else {
      mutators.setValue(`money_handle.${values.donation_type}`, `${index}`);
    }
  }

  async function onFormSubmit(values) {
    await onSubmit(values);
  }

  const customAmountLabel = 'Or enter your own amount';

  const getCurrentImpact = (values) => {
    if (!values) {
      return null;
    }

    let currentImpact;
    if (values.donation_type === 'single_donation' && values.amount.single_donation) {
      currentImpact = getImpact(+values.amount.single_donation, impactsSingleDonation);
    }

    if (values.donation_type === 'recurring_donation' && values.amount.recurring_donation) {
      currentImpact = getImpact(+values.amount.recurring_donation, impactsMonthlyDonation);
    }

    if (currentImpact) {
      return currentImpact;
    }

    // Keep the impact with the last know valid amount.
    if (
      values.donation_type === 'single_donation' &&
      values.money_handle.last_valid &&
      values.money_handle.last_valid.single_donation
    ) {
      currentImpact = getImpact(
        +values.money_handle.last_valid.single_donation,
        impactsSingleDonation,
      );
    }

    if (
      values.donation_type === 'recurring_donation' &&
      values.money_handle.last_valid &&
      values.money_handle.last_valid.recurring_donation
    ) {
      currentImpact = getImpact(
        +values.money_handle.last_valid.recurring_donation,
        impactsMonthlyDonation,
      );
    }
    return currentImpact || null;
  };

  const classes = [
    'bb',
    'bb-appeal-hero-with-money-handles',
    styles['appeal-hero-with-money-handles'],
    styles['bb-appeal-hero-with-money-handles'],
    `styling-${styling}`,
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <div id={uuid} className={classes.join(' ')}>
      <HeroImageWithShapedBackground image={image} showDec={styling === 'dec'} />
      <div className="container">
        <div className="form-card">
          <h1 className="header-two large">{title}</h1>

          <Form
            onSubmit={onFormSubmit}
            initialValues={initialValues}
            mutators={{
              // Create a mutator which allows to modify field's value by external callbacks.
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            subscription={{ submitting: true, values: true }}
            render={({ handleSubmit, submitting, values, form: { mutators } }) => (
              <form onSubmit={handleSubmit}>
                {optionsSingle.length && optionsMonthly.length ? (
                  <RadiosButton
                    name="donation_type"
                    mutateFormValue={mutators.setValue}
                    options={[
                      { value: 'single_donation', label: singleDonationLabel },
                      { value: 'recurring_donation', label: recurringDonationLabel },
                    ]}
                  />
                ) : (
                  <div className="donation-type">
                    <span>{optionsMonthly.length ? 'Monthly donation' : 'Single donation'}</span>
                  </div>
                )}

                <HeroMoneyHandles
                  moneyHandles={moneyHandles}
                  donationType={values.donation_type}
                  currentImpact={getCurrentImpact(values)}
                  mutateFormValue={mutators.setValue}
                />

                <label className="field-amount-label" htmlFor={`amount.${values.donation_type}`}>
                  {customAmountLabel}
                </label>
                <Field
                  name={`amount.${values.donation_type}`}
                  type="text"
                  parse={parseOnlyDigits}
                  validate={minValue(1)}
                >
                  {({ input }) => (
                    <TextField
                      name={`amount.${values.donation_type}`}
                      id={`amount.${values.donation_type}`}
                      onChange={(ev) => onAmountChange(ev, input, values, mutators)}
                      icon={currencySymbolPath[currency]}
                      label=""
                      placeholder=""
                      // Enable numeric keyboard for mobile devices.
                      inputMode="numeric"
                      pattern="[0-9]+"
                      infoOnPatternNotMatched="Please use numbers only"
                      aria-label={getSymbol(currency)}
                    />
                  )}
                </Field>

                <Button
                  type={styling === 'normal' ? 'primary' : 'emergency'}
                  size="extra-large"
                  isLoading={submitting}
                  tabIndex={0}
                  withArrow
                >
                  DONATE NOW
                </Button>
              </form>
            )}
          />
          {bottomDescription && <p className="bottom-description">{bottomDescription}</p>}
        </div>
      </div>
    </div>
  );
};

BBHeroWithMoneyHandles.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape(imagePropTypes),
  currency: PropTypes.oneOf(['GBP', 'EUR']).isRequired,
  defaultRecurrence: PropTypes.oneOf(['single_donation', 'recurring_donation']),
  optionsSingle: PropTypes.arrayOf(PropTypes.shape(moneyHandlePropTypes)),
  optionsMonthly: PropTypes.arrayOf(PropTypes.shape(moneyHandlePropTypes)),
  preselectedSingle: PropTypes.number,
  preselectedMonthly: PropTypes.number,
  bottomDescription: PropTypes.string,
  singleDonationLabel: PropTypes.string,
  recurringDonationLabel: PropTypes.string,
  styling: PropTypes.oneOf(['normal', 'emergency', 'dec']),
  onSubmit: PropTypes.func.isRequired,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string.isRequired,
  defaultAppealId: PropTypes.string.isRequired,
  defaultAppealTitle: PropTypes.string.isRequired,
  impactsSingleDonation: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      text: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
    }),
  ),
  impactsMonthlyDonation: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      text: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
    }),
  ),
};

export default BBHeroWithMoneyHandles;
