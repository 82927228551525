import React from 'react';
import PropTypes from 'prop-types';
import { Field, TextField } from '../../../../01_atoms/FormElements';
import { parseOnlyDigits } from '../../../../../utils/formFieldParsers';
import { currencies, getSymbol } from '../../../../../utils/currency';
import FieldError from '../../../../01_atoms/FormElements/FieldError';
import { propTypes as moneyHandlePropTypes } from '../../BBHeroWithMoneyHandles/HeroMoneyHandles';

import styles from './index.module.scss';

const MoneyHandlesWithCustomAmount = ({
  formId,
  moneyHandles,
  donationType,
  mutateFormValue,
  currency,
  styling = 'default',
}) => {
  const moneyHandlesName = `${formId}_money_handle.${donationType}`;
  const customAmountName = `${formId}_custom_amount.${donationType}`;
  const amountName = `${formId}_amount.${donationType}`;

  function onMoneyHandleChange(event, input) {
    // Call default onChange handler.
    input.onChange(event, input);

    const moneyHandleIndex = event.target.value;
    const moneyHandleAmount = moneyHandles[donationType][moneyHandleIndex].amount;
    // Updates the amount on money handle change.
    mutateFormValue(amountName, `${moneyHandleAmount}`);
    // Update last valid amount for keeping its impact text.
    mutateFormValue(`${formId}_money_handle.last_valid.${donationType}`, moneyHandleAmount);
    // Reset custom amount field on money handle change.
    mutateFormValue(customAmountName, '');
  }

  function onKeyDownPress(e, item) {
    const enter = 13;
    if (e.keyCode === enter && mutateFormValue) {
      // Updates checked state
      mutateFormValue(moneyHandlesName, `${item.value}`);
      // Updates the amount on money handle change.
      mutateFormValue(amountName, `${item.amount}`);
      // Reset custom amount field on money handle change.
      mutateFormValue(customAmountName, '');
    }
  }

  // Update selected money handle on custom amount change.
  function onAmountChange(event, input) {
    // Call default onChange handler.
    input.onChange(event);

    const amount = parseInt(event.target.value, 10);
    const index = moneyHandles[donationType].findIndex((i) => i.amount === amount);
    // Store the last valid amount from the money handles to keep impact message for it.
    const currentAmountIndex = moneyHandles[donationType].findIndex((i) => i.amount === amount);
    if (currentAmountIndex >= 0) {
      mutateFormValue(`${formId}_money_handle.last_valid.${donationType}`, amount);
    }

    if (index === -1) {
      // Reset radio button.
      mutateFormValue(moneyHandlesName, undefined);
    } else {
      mutateFormValue(moneyHandlesName, `${index}`);
    }

    mutateFormValue(amountName, `${amount}`);
  }

  const classes = [
    styles['money-handles-custom'],
    styles[`money-handles-custom--${styling}`],
    'field-type-radios-button',
    `field-${moneyHandlesName.replace('.', '_')}`,
  ];

  return (
    <Field name={moneyHandlesName} type="radio_button">
      {({ input: { ...radioInput } }) => (
        <div
          className={classes.join(' ')}
          data-component="money-handles-custom"
          role="group"
          aria-labelledby={`radios_button_${moneyHandlesName}`}
        >
          <div
            className={`${styles['money-handles-custom__buttons']} ${styles[`money-handles-custom__buttons--${moneyHandles[donationType].length}`]}`}
            role="radiogroup"
            aria-label={moneyHandlesName}
          >
            {moneyHandles[donationType].map((item) => (
              <Field
                name={moneyHandlesName}
                key={`${moneyHandlesName}_${item.value}`}
                render={({ input }) => (
                  <label
                    htmlFor={`${moneyHandlesName}_${item.value}`}
                    tabIndex={0}
                    className={`${styles['money-handles-custom__button']} ${item.value === input.value ? styles[`money-handles-custom__button--selected`] : ''} ${item.className ? item.className : ''}`}
                    data-component={
                      item.value === input.value
                        ? 'money-handles-custom__button--selected'
                        : 'money-handles-custom__button'
                    }
                    aria-labelledby={`${moneyHandlesName}_${item.value}`}
                    onKeyDown={(e) => onKeyDownPress(e, item)}
                    aria-checked={item.value === input.value}
                    role="radio"
                  >
                    <Field
                      component="input"
                      aria-label={item.label}
                      type="radio"
                      name={moneyHandlesName}
                      value={item.value}
                      id={`${moneyHandlesName}_${item.value}`}
                      title={item.value}
                      onChange={(ev) => onMoneyHandleChange(ev, radioInput)}
                    />
                    {item.label}
                  </label>
                )}
              />
            ))}
            <div className={styles['money-handles-custom__custom-amount']}>
              <Field name={customAmountName} type="text" parse={parseOnlyDigits}>
                {({ input }) => (
                  <TextField
                    name={customAmountName}
                    id={customAmountName}
                    onChange={(ev) => onAmountChange(ev, input)}
                    icon={
                      <span className={styles['money-handles-custom__custom-amount-icon']}>
                        {currencies[currency]}
                      </span>
                    }
                    label=""
                    placeholder="Other amount"
                    // Enable numeric keyboard for mobile devices.
                    inputMode="numeric"
                    pattern="[0-9]+"
                    infoOnPatternNotMatched="Please use numbers only"
                    aria-label={getSymbol(currency)}
                  />
                )}
              </Field>
            </div>
          </div>

          <FieldError name={name} />
        </div>
      )}
    </Field>
  );
};

MoneyHandlesWithCustomAmount.propTypes = {
  formId: PropTypes.string.isRequired,
  moneyHandles: PropTypes.shape({
    single_donation: PropTypes.arrayOf(PropTypes.shape(moneyHandlePropTypes)),
    recurring_donation: PropTypes.arrayOf(PropTypes.shape(moneyHandlePropTypes)),
  }).isRequired,
  donationType: PropTypes.oneOf(['single_donation', 'recurring_donation']).isRequired,
  mutateFormValue: PropTypes.func.isRequired,
  currency: PropTypes.oneOf(['GBP', 'EUR']).isRequired,
  styling: PropTypes.oneOf(['default', 'emergency']),
};

export default MoneyHandlesWithCustomAmount;
