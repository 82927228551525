import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import DefaultPicture, {
  propTypes as defaultPicturePropTypes,
} from '../../../01_atoms/DefaultPicture';
import LoopingVideo from '../../../01_atoms/LoopingVideo';

export const HeroBackground = ({
  imagePicture,
  videoURL = '',
  uuid = null,
  className = '',
  children,
}) => (
  <div
    className={`bb bb-hero ${styles['bb-hero']} ${videoURL ? ' bb-hero--video' : ''} ${className}`}
    title={imagePicture.alt && !videoURL ? imagePicture.alt : null}
    id={uuid}
  >
    <div className="view-box-wrapper">
      <div className="image-box">
        <DefaultPicture
          className={`responsive-image ${videoURL ? 'd-md-none' : ''}`}
          {...imagePicture}
        />

        {videoURL && <LoopingVideo src={videoURL} />}
      </div>
    </div>
    {children}
  </div>
);

HeroBackground.propTypes = {
  uuid: PropTypes.string,
  imagePicture: PropTypes.shape(defaultPicturePropTypes).isRequired,
  videoURL: PropTypes.string,
  className: PropTypes.string,
};

export default HeroBackground;
