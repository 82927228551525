import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import ResponsiveHeroImage from '../BBHero';

/**
 * APPEAL HERO WITH MONEY HANDLES FORM.
 *
 * Responsive images for mobile & tablet and a fixed background for desktop.
 */
const HeroImageWithShapedBackground = ({
  image: { small, medium, large } = {},
  showDec = false,
}) => (
  <>
    {small && medium && (
      <div className="d-lg-none cover-image">
        <ResponsiveHeroImage
          image={{ small, medium, large: {} }}
          presets={[
            {
              name: 'small',
              className: 'responsive-image responsive-image-mobile',
              query: '(max-width: 420px)',
              width: 420,
              height: 168,
            },
            {
              name: 'medium',
              className: 'responsive-image responsive-image-tablet',
              query: '(min-width: 421px) and (max-width: 992px)',
              width: 992,
              height: 397,
            },
          ]}
        />
        {showDec && (
          <>
            <div className="red-strip" />
            <img src="/static/icons/DEC.svg" className="watermark" alt="DEC logo" />
          </>
        )}
      </div>
    )}
    {large && large.url ? (
      <>
        <div className="d-none d-lg-block shaped-background">
          <Head>
            <link rel="preload" href={large.url} as="image" media="(min-width: 993px)" />
          </Head>
          <div
            className="image"
            style={{ backgroundImage: `url('${large.url}')` }}
            title={large.alt || ''}
          />
          <div className="shape" />
        </div>
        {showDec && (
          <div className="d-none d-lg-block">
            <div className="red-strip" />
            <img src="/static/icons/DEC.svg" className="watermark" alt="DEC logo" />
          </div>
        )}
      </>
    ) : (
      <div className="d-none d-lg-block shaped-background no-image">
        <div className="shape" />
      </div>
    )}
  </>
);

HeroImageWithShapedBackground.propTypes = {
  image: PropTypes.shape({
    small: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
    medium: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
    large: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
  }),
  showDec: PropTypes.bool,
};

export default HeroImageWithShapedBackground;
