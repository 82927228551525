import React from 'react';
import PropTypes from 'prop-types';
import { Field, RadiosButton } from '../../../01_atoms/FormElements';
import ImpactText from '../../../01_atoms/ImpactText';

/**
 * APPEAL HERO WITH MONEY HANDLES FORM.
 *
 * Preparing and rendering money handles with impact description.
 */
const MoneyHandles = ({ moneyHandles, donationType, currentImpact = null, mutateFormValue }) => {
  function onMoneyHandleChange(event, input) {
    // Call default onChange handler.
    input.onChange(event);

    const moneyHandleIndex = event.target.value;
    const moneyHandleAmount = moneyHandles[donationType][moneyHandleIndex].amount;
    // Updates custom amount field on money handle change.
    mutateFormValue(`amount.${donationType}`, `${moneyHandleAmount}`);
  }
  const options = currentImpact
    ? moneyHandles[donationType].map((item) =>
        item.amount === currentImpact.amount ? { ...item, className: 'show-impact-arrow' } : item,
      )
    : moneyHandles[donationType];

  function onKeyDownPress(e, item) {
    const enter = 13;
    if (e.keyCode === enter && mutateFormValue) {
      // Updates checked state
      mutateFormValue(`money_handle.${donationType}`, `${item.value}`);
      // Updates custom amount field on money handle change.
      mutateFormValue(`amount.${donationType}`, `${item.amount}`);
    }
  }

  return (
    <>
      <Field name={`money_handle.${donationType}`} type="RadiosButton">
        {({ input }) => (
          <RadiosButton
            name={`money_handle.${donationType}`}
            size="large"
            label="How much would you like to give?"
            onChange={(ev) => onMoneyHandleChange(ev, input)}
            onKeyDown={onKeyDownPress}
            mutateFormValue={mutateFormValue}
            options={options}
          />
        )}
      </Field>
      {currentImpact && (
        <ImpactText
          text={currentImpact.text}
          image={currentImpact.image}
          className="impact-message-container"
        />
      )}
    </>
  );
};

const propTypes = {
  amount: PropTypes.number.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
};

MoneyHandles.propTypes = {
  moneyHandles: PropTypes.shape({
    single_donation: PropTypes.arrayOf(PropTypes.shape(propTypes)),
    recurring_donation: PropTypes.arrayOf(PropTypes.shape(propTypes)),
  }).isRequired,
  donationType: PropTypes.oneOf(['single_donation', 'recurring_donation']).isRequired,
  mutateFormValue: PropTypes.func.isRequired,
  currentImpact: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    text: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  }),
};

export { propTypes };

export default MoneyHandles;
